.form__group {
  width: 100%;
  .form__label {
    @include label();
  }
  .form__container {
    position: relative;
    .form__field {
      @include input();
    }
    .form__icon {
      position: absolute;
      top: 7px;
      left: 15px;
      .mdi-icon {
        width: 18px;
        height: 18px;
        color: $grayscale-2;
      }
    }
    &.form--no-icon .form__field {
      padding-left: 0 !important;
    }
  }
  .form__error {
    color: $red;
    font-size: .95rem;
    margin-top: .5rem;
  }
  .form__warning {
    font-size: .95rem;
    margin-top: .5rem;
  }
}
/* React Datepicker Overrides */
.react-datepicker-wrapper {
  width: 100%;
  input:disabled {
    background-color: hsl(0, 0%, 95%);
  }
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
/* React Select Overrides */
.select--custom__control {
  @include input();
  padding: 0 0 0 2.5rem !important;
  box-shadow: none !important;
  border-color: $grayscale-3 !important;
}
.form--no-icon .select--custom__control {
  padding-left: 0 !important;
}
.select--custom__control--is-focused {
  border: none;
  &:hover {
    border-color: #4A4A49 !important;
  }
  //box-shadow: 0 0 0 1px black !important;
}
.no-padding {
  .select--custom__control {
    padding-left: 0 !important;
  }
}
/* React Date Picker Overrides */
.react-datepicker__close-icon {
  &:after {
    color: hsl(0,0%,80%);
    cursor: pointer;
    background-color: transparent;
    border-radius: 0px;
    height: 100%;
    width: 100%;
    font-size: 23px;
    font-weight: bold;
    padding: 3px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
  }
}
.react-datepicker-popper, .react-date-picker {
  z-index: 2000 !important;
}
.pretty {
  z-index: 0;
}
