/* Footer */
.page.page--login {
  display: flex;
  width: 100%;
  height: 100%;
  .page__footer {
    margin: 0 2%;
    padding: 0;
    .page__container {
      background: $blue-dark;
      width: 100%;
      color: white;
      text-align: center;
    }
  }
}
/* Content */
.page.page--login {
  .page__content {
    margin: 0 2%;
    flex-grow: 1;
    background: linear-gradient(180deg, #009494 0%, #006992 100%);
    @media (min-width: 768px) {
      margin: 0 2%;
    }
    .page__container {
      max-width: 95%;
      @media (min-width: 768px) {
        max-width: 75%;
      }
      @media (min-width: 1440px) {
        max-width: 45%;
      }
      display: flex;
      height: 100%;
      background: $blue-dark;
      background: linear-gradient(180deg, $teal 0%, $blue-dark 100%);
    }
  }
}
/* Reset Password */
.page.page--reset-password {
  .page__content {
    @media (min-width: 1023px) {
      margin: 0 20%;
    }
  }
}
/* Form */
.form--login {
  width: 100%;
  min-width: inherit;
  @media (min-width: 1024px) {
    min-width: 600px;
  }
}
