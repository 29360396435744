.impersonation_wrapper {
  border: 2px solid #828282;
  border-radius: .5rem;
  padding: 2rem 2rem .5rem 2rem;
  position: relative;
  margin: .25rem;
}

.impersonation_row {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.impersonation_element-wrapper {
  padding: 0 1rem;
  background: #edf2f7;

}
