//   -------------------------------------------------------
//   General
//   -------------------------------------------------------
.with-help {
  position: relative;
  .help-icon {
    margin-top: .25rem;
    margin-left: .25rem;
    border-radius: 50%;
    background: $teal;
    color: white;
    padding: 2px;
    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
    }
  }
}
//   -------------------------------------------------------
//   Layout
//   -------------------------------------------------------
.chart-block {
  margin-bottom: 2rem;
  .chart-headline {
    h5 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    p {
      color: $text-light;
    }
  }
  .chart-content {
    margin-top: 3rem;
    @media(max-width: 768px) {
      overflow-x: auto;
    }
  }
}
.chart-block:last-child {
  margin-bottom: 0 !important;
}
//   -------------------------------------------------------
//   Table Chart
//   -------------------------------------------------------
.data-vis-1-dropdown {
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 25%;
  max-width: 25%;
  @media(max-width: 1441px) {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
  @media(max-width: 1025px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  @media(max-width: 992px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.btn-chart-report {
  margin: auto 15px auto auto;
  @media(max-width: 992px) {
    width: 100%;
    margin: 1rem 15px !important;
  }
}

.charts-css.line{
  .labels-column {
    border-left: 0 !important;
    border-bottom: 0 !important;
    background: white !important;
    td{
      text-align: left;
      align-items: flex-end !important;
      padding-right: 10px;
      display: flex;
      .data{
        transform: translate(0%, 7px) !important;
        font-size: 0.8em;
        color: #4a4a49;
      }
      &:before {
        opacity: 0 !important;
      }
      &:after {
        opacity: 0 !important;
      }
      &:last-child{
        .data {
          transform: translate(0%, -6px) !important;
        }
      }
    }
  }
  &.show-labels tr th{
    font-size: 0.8em;
    color: #4a4a49;
  }
}

/** Line Charts **/

.line-chart-bg-1{background: #268282;}
.line-chart-bg-2{background: #af7d09;}
.line-chart-bg-3{background: #0e5a98;}
.line-chart-bg-4{background: #8cc5f4;}
.line-chart-bg-5{background: #9F220D;}
.line-chart-bg-6{background: #f1664e;}
.line-chart-bg-7{background: #6e52a1;}
.line-chart-bg-8{background: #6d7374;}

.charts-css {
  --color-1: #268282 !important;
  --color-2: #af7d09 !important;
  --color-3: #0e5a98 !important;
  --color-4: #8cc5f4 !important;
  --color-5: #9F220D !important;
  --color-6: #f1664e !important;
  --color-7: #6e52a1 !important;
  --color-8: #6d7374 !important;
  --chart-bg-color: #f5f5f5;
  --heading-size: 0px;
  --primary-axis-color: rgba(0, 0, 0, 1);
  --primary-axis-style: solid;
  --primary-axis-width: 1px;
  --secondary-axes-color: rgba(0, 0, 0, .15);
  --secondary-axes-style: solid;
  --secondary-axes-width: 1px;
  --data-axes-color: rgba(0, 0, 0, .15);
  --data-axes-style: solid;
  --data-axes-width: 1px;
  --legend-border-color: rgb(200, 200, 200);
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
  color-adjust: exact;
  box-sizing: border-box;
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &::before {
    box-sizing: border-box;
  }
  caption {
    display: none;
  }
  &:not(.legend-inline) {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
  }
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  overflow: initial;
  background-color: transparent;
  caption {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  colgroup {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    display: none;
  }
  tbody {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  td {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  th {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  thead {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    display: none;
  }
  tr {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  tfoot {
    display: none;
  }
}
.charts-css.line {
  tbody {
    tr {
      td {
        &:nth-of-type(10n+1) {
          &::before {
            background: var(--color,var(--color-1));
          }
        }
        &:nth-of-type(10n+2) {
          &::before {
            background: var(--color,var(--color-2));
          }
        }
        &:nth-of-type(10n+3) {
          &::before {
            background: var(--color,var(--color-3));
          }
        }
        &:nth-of-type(10n+4) {
          &::before {
            background: var(--color,var(--color-4));
          }
        }
        &:nth-of-type(10n+5) {
          &::before {
            background: var(--color,var(--color-5));
          }
        }
        &:nth-of-type(10n+6) {
          &::before {
            background: var(--color,var(--color-6));
          }
        }
        &:nth-of-type(10n+7) {
          &::before {
            background: var(--color,var(--color-7));
          }
        }
        &:nth-of-type(10n+8) {
          &::before {
            background: var(--color,var(--color-8));
          }
        }
        &:nth-of-type(10n+9) {
          &::before {
            background: var(--color,var(--color-9));
          }
        }
        &:nth-of-type(10n+10) {
          &::before {
            background: var(--color,var(--color-10));
          }
        }
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
        }
        &::after {
          content: "";
          width: 100%;
        }
      }
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      overflow-wrap: anywhere;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      th {
        position: absolute;
        right: 0;
        left: 0;
      }
    }
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    height: calc(100% - var(--heading-size));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  &:not(.show-labels) {
    --labels-size: 0;
    tbody {
      tr {
        th {
          display: none;
        }
      }
    }
  }
  --line-size: 3px;
  &:not(.reverse) {
    tbody {
      tr {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-margin-after: var(--labels-size);
        margin-block-end: var(--labels-size);
        th {
          bottom: calc(var(--labels-size) * -1 - var(--primary-axis-width));
          height: var(--labels-size);
        }
        td {
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
      }
    }
    &:not(.reverse-data) {
      tbody {
        tr {
          td {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            &::before {
              clip-path: polygon(0 calc(100%*(1 - var(--start, var(--size)))),100% calc(100%*(1 - var(--size))),100% calc(100%*(1 - var(--size)) - var(--line-size)),0 calc(100%*(1 - var(--start, var(--size))) - var(--line-size)));
            }
            .data {
              transform: translateX(50%);
            }
            &::after {
              height: calc(100%*var(--size));
            }
          }
        }
      }
    }
  }
  &:not(.reverse-data) {
    tbody {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  &:not(.reverse-datasets) {
    tbody {
      tr {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
      }
    }
  }
  &:not(.reverse).reverse-data {
    tbody {
      tr {
        td {
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          &::before {
            clip-path: polygon(0 calc(100%*(1 - var(--size))),100% calc(100%*(1 - var(--start, var(--size)))),100% calc(100%*(1 - var(--start, var(--size))) - var(--line-size)),0 calc(100%*(1 - var(--size)) - var(--line-size)));
          }
          .data {
            transform: translateX(-50%);
          }
          &::after {
            height: calc(100%*var(--size));
          }
        }
      }
    }
  }
}
.charts-css.hide-data {
  .data {
    opacity: 0;
  }
}
.charts-css.line.show-labels {
  --labels-size: 1.5rem;
  tbody {
    tr {
      th {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: var(--labels-align,center);
        -ms-flex-pack: var(--labels-align,center);
        justify-content: var(--labels-align,center);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
    }
  }
  th.hide-label {
    display: none;
  }
  tr.hide-label {
    th {
      display: none;
    }
  }
}
.charts-css.line.show-primary-axis {
  &:not(.reverse) {
    tbody {
      tr {
        border-block-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
      }
    }
  }
}
.charts-css.line.show-primary-axis.reverse {
  tbody {
    tr {
      border-block-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
    }
  }
}
.charts-css.line.show-10-secondary-axes {
  &:not(.reverse) {
    tbody {
      tr {
        background-size: 100% 10%;
        background-image: -webkit-gradient(linear,left top,left bottom,from(var(--secondary-axes-color)),to(transparent));
        background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width),transparent var(--secondary-axes-width));
      }
    }
  }
}

/** Chart Table **/

#chart-table-data-vis-1 {
  .benchmarks-col {
    max-width: 25%;
    width: 25%;
  }
  .bar-col {}
  .achieved-col {
    width: 8%;
    max-width: 8%;
  }
  .previous-col {
    max-width: 15%;
    width: 15%;
  }
  .indicator-col {
    max-width: 10%;
    width: 10%;
  }
  @media(max-width: 1441px) {
    .indicator-col {
      max-width: 12% !important;
      width: 12% !important;
    }
    .achieved-col {
      max-width: 10% !important;
      width: 10% !important;
    }
  }
  @media(max-width: 1236px) {
    .indicator-col {
      max-width: 17% !important;
      width: 17% !important;
    }
  }
  @media(max-width: 1025px) {
    .benchmarks-col {
      max-width: 20% !important;
      width: 20% !important;
    }
    .previous-col {
      max-width: 25% !important;
      width: 25% !important;
    }
  }
}
#chart-table-data-vis-2 {
  &.table-content {
    overflow: unset !important;
  }
  .benchmarks-col {
    max-width: 25%;
    width: 25%;
  }
  .nationally-col {
    width: 15%;
    max-width: 15%;
  }
  .hubs-col, .leps-col {
    width: 20%;
    max-width: 295px;
  }
  .leps-col {
    .dropdown-menu {
      max-width: 100% !important;
      min-width: inherit !important;
      .dropdown-item {
        white-space: inherit !important;
      }
    }
  }
  @media(max-width: 1025px)  {
    &.table-content {
      overflow: auto !important;
    }
    .hubs-col, .leps-col {
      max-width: 100px !important;
    }
    .leps-col {
      .dropdown-menu {
        min-width: 300px !important;
        .dropdown-item {
          white-space: nowrap !important;
        }
      }
    }
  }
}
