//   -------------------------------------------------------
//   Transitions
//   -------------------------------------------------------
@mixin transition($what: all, $time: .3s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition:    $what $time $how;
  -ms-transition:     $what $time $how;
  -o-transition:      $what $time $how;
  transition:         $what $time $how;
}
//   -------------------------------------------------------
//   Fonts - Texts
//   -------------------------------------------------------
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
// Body text - font-family: 'Lato', sans-serif;
@mixin change-font($font: 'Lato'){
  font-family: $font, sans-serif !important;
}
body {
  @include change-font('Lato');
  font-size: 16px;
  color: $grayscale-4;
}
h1,h2,h3,h4,h5,h6 {
  @include change-font();
  margin: 0;
  color: $grayscale-5;
}
.font-10 {font-size:.63rem!important;}
.font-12 {font-size:.75rem!important;}
.font-14 {font-size:.88rem!important;}
.font-16 {font-size:1rem!important;}
.font-18 {font-size:1.13rem!important;}
.font-20 {font-size:1.25rem!important;}
.font-22 {font-size:1.38rem!important;}
.font-24 {font-size:1.5rem!important;}
.underline {text-decoration:underline;}
.opacity-100 {
  opacity: 1;
}
.opacity-75 {
  opacity: .75;
}
.opacity-50 {
  opacity: .5;
}
.opacity-25 {
  opacity: .25;
}
.pointer {
  cursor: pointer;
}
//   -------------------------------------------------------
//   Animations - Effects - Keyframes - Transitions
//   -------------------------------------------------------
// ----- Keyframes.
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(-12.5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(12.5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(3.125rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes white-to-gray {
  from {
    color: white;
  }
  to {
    color: $yellow;
  }
}
// ----- Animations.
.fade-in {
  animation: fade-in ease 1.5s forwards;
}
.fade-out {
  animation: fade-out ease 1.5s forwards;
}
.fade-in-left {
  animation: fade-in-left ease 1.5s forwards;
}
.fade-in-right {
  animation: fade-in-right ease 1.5s forwards;
}
.fade-in-bottom {
  opacity: 0;
  animation: fade-in-bottom ease 1s forwards;
}
.animation-delay {
  animation-delay: 2s;
}
.rotate-off {
  transition: all 450ms ease-in-out;
  transform: rotate(0);
}
.rotate-in {
  transition: all 450ms ease-in-out;
  transform: rotate(180deg);
}
// ----- Effects.
.pulse {
  @include transition();
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
  -webkit-animation: none;
  &:hover {
    -webkit-animation: pulse 1.5s 1;
    background: radial-gradient(ellipse at center,  #fff 0%, #e7e7e7 100%);
  }
}
@-webkit-keyframes pulse-animation {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse-animation {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0,168,168, .75);
    box-shadow: 0 0 0 0 rgba(0,168,168, .75);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0,168,168, 0);
    box-shadow: 0 0 0 10px rgba(0,168,168, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0,168,168, 0);
    box-shadow: 0 0 0 0 rgba(0,168,168, 0);
  }
}
// ----- General transitions.
.on {
  opacity: 1 !important;
}
// ----- Loaders.
@keyframes loader-progress{
  0%{
    left: -100%;
    background: rgba($teal, .5);
  }
  50%{
    left: 100%;
    background: rgba($teal, .5);
  }
  51%{
    background: rgba(white, .5);
  }
  100%{
    background: rgba(white, .5);
  }
}

@mixin loader {
  width: 5rem;
  height: .5rem;
  overflow: hidden;
  position: relative;
  margin: auto;
  &::before{
    content: '';
    position: absolute;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba($teal, 1);
    animation: loader-progress 4s linear infinite;
  }
}

.loader {
  @include loader();
  background: rgba($teal-soft, .25);
  &::before{
    background: rgba($teal, 1);
  }
}

.loader--light {
  @include loader();
  background: rgba($yellow, 1);
  &::before{
    background: rgba(0, 0 , 0, 1);
  }
}

@keyframes loader-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-circle {
  display: inline-block;
  &:after {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin: .5rem;
    border-radius: 50%;
    border: .5rem solid $teal-soft;
    border-color: $teal-soft transparent $teal-soft transparent;
    animation: loader-circle 1.5s linear infinite;
  }
}
.loader-spinning {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: loader-circle-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 1.5px solid;
    border-color: #fff transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.teal div {
    border-color: teal transparent transparent transparent !important;
  }

  &.big {
    width: 100px;
    height: 100px;

    div {
      border: 5px solid;
    }
  }
}

@keyframes loader-circle-white {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//   -------------------------------------------------------
//   Other
//   -------------------------------------------------------
.spin {
  animation: spin .75s ease-in-out infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.fancy-list {
  li {
    @include transition();
    position: relative;
    list-style: none;
    padding: .5rem .5rem .5rem 2rem;
    background: transparent;
    &:before {
      @include transition();
      position: absolute;
      left: 3px;
      top: 6px;
      font-family: 'Material Icons';
      font-size: 20px;
      content: 'done';
      display: inline-block;
      vertical-align: middle;
      color: $grayscale-3;
    }
    &:hover {
      background: $bg-light;
    }
    a {
      color: $grayscale-5;
      &:hover {
        color: $teal;
      }
    }
  }
}
