//   -------------------------------------------------------
//   Fit screen
//   -------------------------------------------------------
html, body, #page-wrapper {
  height: 100%;
  margin: 0;
  padding: 0;
}
body, #page-wrapper {
  width: 100%;
  min-height: 100%;
}
#sidebar, #content {
  height: 100%;
}
.fixed-padding {
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.full-width {
  width: 100%;
}
//   -------------------------------------------------------
//   Header & Footer - Dropdown Menus
//   -------------------------------------------------------
.header-toggle-sidebar {
  margin: auto 0;
  i {
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
    margin-left: -.75rem;
    color: #222221;
    display: block;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &:before {
      transition: all .5s;
    }
    &:hover {
      color: $teal;
      transition: all 0.3s;
    }
    &.collapsed {
      color: $teal;
      &:before {
        -o-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
}
#default-footer {
  .footer-container {
    display: flex;
  }
}
#default-header {
  min-height: 120px;
  display: flex;
  padding: 1.5rem 1.5rem 1.5rem 0;
  position: relative;
  background: white;
  .header-nav {
    margin: auto 0 auto auto;
    text-align: right;
    #header-alerts, #menu-options {
      vertical-align: -1rem;
    }
    #header-alerts {
      display: inline-grid;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      border-right: 1px solid $grayscale-3;
      i {
        font-size: 1.5rem;
      }
      .alerts-true {
        transition: all 0.3s;
        position: relative;
        text-align: center;
        margin: 0 auto;
        width: fit-content;
        color: white;
        &:hover {
          cursor: pointer;
          color: $grayscale-5;
        }
        .notifications-number {
          position: absolute;
          top: .5rem;
          right: -.5rem;
          width: 20px;
          height: 15px;
          background: $red;
          color: white;
          border-radius: 1.5rem;
          border: 2px solid $teal;
          font-size: .65rem;
          font-weight: bold;
          text-align: center;
          line-height: 13px;
        }
      }
      .alerts-false {
        color: white;
        &:hover {
          cursor: pointer;
          color: $grayscale-5;
        }
      }
    }
    .header-menu {
      display: initial;
      #menu-switch, #menu-options {
        display: inline;
        //cursor: pointer;
        text-align: center;
      }
      #menu-switch {
        display: inline-grid;
        margin-right: 1.5rem;
        /*&:hover {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        }*/
        .menu-username {
          padding: .5rem;
          background: $bg-light;
          text-align: center;
          border-radius: .25rem;
          font-size: .85rem;
          span {
            color: $text-light;
          }
          .user {
            margin-bottom: .25rem;
            span {
              margin-left: .5rem;
              background: white;
              padding: .25rem .75rem;
              border-radius: .25rem;
              font-size: .75rem;
            }
          }
          .institution {
            font-size: .75rem;
            strong {
              font-weight: normal;
            }
          }
        }
        .responsive-switch {
          transition: all 0.3s;
          display: none;
          width: 36px;
          height: 36px;
          font-size: 1.5rem;
          text-align: center;
          &:hover {
            color: $teal;
          }
        }
      }
      #menu-options {
        cursor: pointer;
        i {
          transition: all 0.3s;
          display: inline-block;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: transparent;
          font-size: 1.5rem;
          &:hover {
            background: $bg-light;
          }
          &:before {
            transition: all .5s;
          }
          &.down {
            color: $teal;
            background: $bg-light;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            &:before {
              -o-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
        &:hover {
          i {
            color: $teal;
          }
        }
      }
    }
  }
}
#options-dropdown {
  width: 270px;
  text-align: left;
}
#accounts-dropdown {
  width: 350px;
  .accounts-list {
    padding: .5rem 0;
    text-align: left;
    .account-element {
      transition: all 0.3s;
      padding: 1rem;
      background: transparent;
      &:hover {
        background: $bg-light;
      }
      .account-check {
        float: left;
        .pretty {
          font-size: 1.5rem;
          margin-right: 0 !important;
          margin-top: .25rem;
        }
        .pretty input:checked~.state.p-success-o label:before, .pretty.p-toggle .state.p-success-o label:before {
          border-color: $teal;
        }
        .pretty input:checked~.state.p-success-o .icon, .pretty input:checked~.state.p-success-o .svg, .pretty input:checked~.state.p-success-o svg, .pretty.p-toggle .state.p-success-o .icon, .pretty.p-toggle .state.p-success-o .svg, .pretty.p-toggle .state.p-success-o svg {
          color: $teal;
        }
      }
      .account-info {
        margin-left: 1rem;
        cursor: pointer;
        .account-type {
          font-weight: bold;
        }
        .account-email {
          display: block;
          color: $text-light;
        }
      }
    }
  }
}
#options-dropdown,#accounts-dropdown {
  transition: all .85s;
  z-index: 9999;
  position: absolute;
  top: -900px;
  right: 0;
  background: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  &.down {
    top: 120px;
  }
  ul {
    li {
      transition: all 0.3s;
      list-style: none;
      background: transparent;
      &:hover {
        background: $bg-light;
      }
      a {
        display: inline-flex;
        align-items: center;
        width: 100%;
        padding: 1.5rem;
        color: $grayscale-5;
        &:hover {
          color: $teal;
        }
        i {
          font-size: 1.25rem;
          margin-right: 1rem;
          color: $teal;
        }
      }
    }
  }
}
// ----- Bell animation.
@keyframes shake {
  0%, 3.3%, 100% {
    transform: rotate(0);
  }
  0.3%, 0.6%, 1%, 1.3%, 1.6%, 2%, 2.3%, 2.6% {
    transform: rotate(6deg);
  }
  3% {
    transform: rotate(4deg);
  } 0.5%, 0.83%, 1.16%, 1.5%, 1.83%, 2.16%, 2.5%, 2.83% {
      transform: rotate(-6deg);
    }
  3.16% {
    transform: rotate(-2deg);
  }
}
.alerts-true:before {
  -webkit-animation: shake 60s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: shake 60s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: shake 60s .7s ease-in-out infinite;
}
//   -------------------------------------------------------
//   Notifications Modal
//   -------------------------------------------------------
#notificationsTab {
  border: none !important;
  margin-top: 1rem;
  text-align: right;
  .nav-item {
    margin-right: 1rem;
  }
  .nav-link, .nav-link:hover, .nav-link.active {
    border: none !important;
  }
  .nav-link {
    color: $grayscale-5 !important;
    background: $bg-light;
    border-color: $grayscale-3;
    text-shadow: none !important;
    &:hover {
      background: $grayscale-3 radial-gradient(circle, transparent 1%, $grayscale-3 1%) center/15000%;
      border-color: $grayscale-3 !important;
    }
    &:active {
      background-color: white;
      background-size: 100%;
      transition: background 0s;
    }
  }
  .nav-link.active {
    color: white !important;
    background: $teal;
    border-color: $teal;
    &:hover {
      background: $teal-dark radial-gradient(circle, transparent 1%, $teal-dark 1%) center/15000%;
      border-color: transparent!important;
    }
    &:active {
      background-color: $teal-soft;
      background-size: 100%;
      transition: background 0s;
    }
  }
}
.notifications-headline {
  display: flex;
  border-bottom: 1px solid #DEE2E6;
  padding-bottom: 1rem;
  p {
    margin: auto 0;
  }
  a {
    margin: auto 0 auto auto;
  }
}
.notifications-block {
  .notification {
    @include transition();
    display: flex;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #dee2e6;
    background: transparent;
    &:hover {
      background: $bg-light;
    }
    .text {
      margin: auto 0 auto 1rem;
      a {
        display: block;
      }
    }
    .actions {
      margin: auto 0;
      text-align: center;
      a {
        display: block;
      }
      svg {
        width: 18px;
        height: 18px;
      }
      .icon-read {
        color: $grayscale-3 !important;
        &:hover {
          color: $teal !important;
        }
      }
    }
    .dismiss {
      margin-left: auto;
    }
    &.read {
      color: $grayscale-3;
      background: #e2e2e2;
      .text {
        a {
          color: $grayscale-3 !important;
          &:hover {
           color: $teal !important;
          }
        }
      }
      .unread-alert {
        background: $grayscale-3 !important;
        animation: none !important;
      }
    }
  }
}
.notifications-block.home {
  .notification {
    padding: .5rem !important;
    margin: 0 !important;
    border: 0;
    .text {
      a {
        display: inline !important;
        margin-left: 1rem;
      }
    }
    .actions {
      min-width: 35px;
    }
  }
  .notification.read {
    border-bottom: 1px solid $grayscale-3 !important;
  }
}
//   -------------------------------------------------------
//   Breadcrumbs
//   -------------------------------------------------------
.breadcrumbs-container {
  li {
    list-style: none;
    display: inline;
  }
  .home {
    font-weight: bold;
  }
  .home,.parent {
    margin-right: 1rem;
    &:after {
      content: ">";
      margin-left: 1rem;
    }
  }
  .active {
    a {
      color: $teal !important;
    }
  }
}
//   -------------------------------------------------------
//   General
//   -------------------------------------------------------
.sidebar-header,.page-content {
  padding: 1.5rem;
}
.sidebar-header {
  height: 120px;
}
//   -------------------------------------------------------
//   Sidebar
//   -------------------------------------------------------
#sidebar {
  transition: all 0.3s;
  float: left;
  min-width: 250px;
  max-width: 250px;
  .sidebar-header {
    text-align: center;
    position: relative;
    background: white;
    img {
      width: 100%;
      height: 100%;
    }
    .sidebar-logo-collapsed,#responsive-menu-btn {
      display: none;
    }
  }
  .sidebar-content {
    .sidebar-menu {
      .parent {
        list-style: none;
        text-transform: uppercase;
        a {
          padding: 1rem 1.5rem;
          display: flex;
          &:hover {
            text-decoration: none;
          }
          .element {
            margin: auto 0;
          }
          .element-dropdown {
            margin: auto 0 auto auto;
            i:before {
              -o-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
          &[aria-expanded="true"] {
            .element-dropdown {
              i:before {
                -o-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
              }
            }
          }
        }
        i {
          font-size: 1.5rem;
          margin-right: 1rem;
          margin-top: -.25rem;
          float: left;
          &:before {
            transition: all 0.3s;
          }
        }
        &.active {
          &:hover,a:hover {
            cursor: default !important;
          }
        }
      }
      .child {
        li {
          list-style: none;
          a {
            word-wrap: break-word;
            @media(min-width: 769px) {
              display: flex;
              padding: .5rem 2rem;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
        .active {
          &:hover,a:hover {
            cursor: default !important;
          }
        }
      }
    }
  }
  // ----- Sidebar Collapsed
  &.collapsed {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    .sidebar-logo-wide {
      display: none !important;
    }
    .sidebar-logo-collapsed {
      display: block !important;
      margin: auto;
    }
    .sidebar-content {
      .sidebar-menu {
        .parent {
          font-size: .8rem;
          padding: 0;
          a {
              padding: .85rem .5rem;
          }
          i {
            margin-right: 0 !important;
            float: none;
          }
          i, span, a {
            display: block;
          }
        }
        .child {
          li {
            max-width: 115px;
            text-align: center;
            padding: 0;
            a {
              display: flex;
              padding: .5rem;
            }
          }
        }
      }
    }
  }
}
//   -------------------------------------------------------
//   Sidebar - Light
//   -------------------------------------------------------
.sidebar-light {
  .sidebar-content {
    background: white;
    .sidebar-menu {
      .parent {
        background: white;
        &:hover {
          background: $teal-soft;
          i {
            color: $teal;
          }
        }
        a {
          color: $grayscale-5;
          &:hover {
            color: $teal;
          }
        }
        &.active {
          background: $teal-soft;
          .element {
            color: $teal !important;
          }
          .element-dropdown {
            i {
              color: $teal;
            }
          }
        }
      }
      .child {
        li {
          &:hover {
            background: $bg-light;
          }
          a {
            color: $grayscale-5;
            &:hover {
              color: $teal;
            }
          }
        }
        li:last-child {
          border-bottom: 1px solid #dee2e6;
        }
        .active {
          background: $bg-light;
          a {
            color: $teal !important;
          }
        }
      }
    }
  }
}
//   -------------------------------------------------------
//   When ifram
//   -------------------------------------------------------
.body-iframe {
  background: $bg-light;
}
//   -------------------------------------------------------
//   Sidebar - Dark
//   -------------------------------------------------------
.body-dark {
  background: $sidebar-light;
}
.sidebar-dark {
  .header-toggle-sidebar {
    i {
      color: white;
      &:hover {
        color: $teal;
      }
      &.collapsed {
        color: white;
      }
    }
  }
  .sidebar-header {
    background: $sidebar-light !important;
  }
  .sidebar-content {
    background: $sidebar-light;
    .sidebar-menu {
      .parent {
        background: $sidebar-light;
        &:hover {
          background: $sidebar;
          i {
            color: $teal;
          }
        }
        a {
          color: white;
          &:hover {
            color: $teal;
          }
        }
        &.active {
          background: $sidebar;
          .element {
            color: $teal !important;
          }
          .element-dropdown {
            i {
              color: $teal;
            }
          }
        }
      }
      .child {
        li {
          &:hover {
            background: $sidebar;
          }
          a {
            color: white;
            &:hover {
              color: $teal;
            }
          }
        }
        li:last-child {
          border-bottom: 1px solid $sidebar;
        }
        .active {
          background: $sidebar;
          a {
            color: $teal !important;
          }
        }
      }
    }
  }
}
//   -------------------------------------------------------
//   Teal Header
//   -------------------------------------------------------
.header-teal {
  background: $teal !important;
  #menu-options i {
    color: white !important;
    &:hover, &.collapsed, &.down {
      color: $sidebar !important;
    }
  }
}
//   -------------------------------------------------------
//   Content
//   -------------------------------------------------------
#content {
  transition: all 0.3s;
  margin-left: 250px;
  background: white;
  &.collapsed {
    margin-left: 100px !important;
  }
}
//   -------------------------------------------------------
//   Page Content
//   -------------------------------------------------------
.page-content {
  background: $bg-light;
  min-height: 100%;
  .content-headline {
    display: flex;
    margin: 2rem 0;
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
      margin: auto 0;
    }
    .headline-button {
      margin: auto 0 auto auto;
    }
  }
  .content-intro {
    @media(min-width: 1440px) {
      width: 65%;
    }
  }
  .content-section {
    padding: 2rem;
    background: white;
    border-radius: .25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  .content-section--with-sticky {
    background: white;
    border-radius: .25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    .content {
      padding: 0 2rem 2rem;
    }
    .headline {
      padding: 2rem;
      background: #ffffff;
      position: sticky;
      top: 0;
      z-index: 49;
    }
  }
}
//   -------------------------------------------------------
//   Login Page
//   -------------------------------------------------------
#page-login {
  position: relative;
  height: 100%;
  .cover-image {
    z-index: -1000;
    position: absolute;
    //background-image: url("./build/images/bg.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    opacity: .15;
  }
  #content-login {
    .login-header {
      background: transparent;
      margin-bottom: 5%;
      .container {
        display: flex;
        padding: 1.5rem 0;
        img {
          margin: auto 0;
        }
        .header-nav {
          margin: auto 0 auto auto;
          ul {
            li {
              display: inline;
            }
          }
        }
      }
    }
    .login-main {
      min-height: 100%;
      max-height: 100%;
      height: auto;
      padding: 0;
      overflow: hidden;
      .login-cover {
        text-align: center;
        .col-left {
          padding-left: 0 !important;
        }
        .col-right {
          padding-right: 0 !important;
        }
        .cover-text, .cover-form {
          border-radius: .25rem;
          h2 {
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
        .cover-text {
          padding: 1.5rem;
          //background: rgba(0, 168, 168, .5);
          background: rgba(248, 249, 250, .5);
          height: 100%;
          text-align: left;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
          .features {
            .feature {
              margin: .5rem 0;
              display: flex;
              i {
                float: left;
                border-right: 1px solid $teal;
                padding-right: 1rem;
                margin-right: 1rem;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 1.5rem;
                color: $teal;
              }
              p {

              }
            }
          }
        }
        .cover-form {
          .form-footer {
            display: flex;
            margin: 1.5rem 0;
            .remember-me {
              margin: auto 0;
            }
            .forgotten {
              margin: auto 0 auto auto;
            }
          }
        }
      }
    }
    .login-footer {
      margin-top: 5%;
    }
  }
}
//   -------------------------------------------------------
//   Responsive
//   -------------------------------------------------------
.hamburger {
  padding: 15px 15px 0 !important;
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: white !important;
  }
}
.hamburger-dark {
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: black !important;
  }
}
@media(max-width: 992px) {
  #page-login {
    #content-login {
      .login-header {
        margin-bottom: 1.5rem !important;
      }
      .login-footer {
        margin-top: 1.5rem !important;
      }
      .login-cover {
        .col-lg-6 {
          margin: 1.5rem 0;
          padding: 0 !important;
        }
      }
    }
  }
}
@media(max-width: 769px) {
  #default-header {
    padding: 1.5rem !important;
    .header-toggle-sidebar {
      display: none;
    }
  }
  footer {
    .footer-container {
      display: block !important;
      text-align: center;
      img {
        width: 150px !important;
        margin-bottom: 2rem;
      }
    }
  }
  #sidebar {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    .sidebar-logo-wide {
      display: none !important;
    }
    .sidebar-logo-collapsed {
      display: block !important;
      margin: auto;
    }
    .sidebar-content {
      .sidebar-menu {
        .parent {
          padding: .85rem .5rem !important;
          font-size: .8rem;
          a {
            padding: 0 !important;
          }
          span {
            font-size: .85rem;
          }
          i {
            margin-right: 0 !important;
            float: none;
          }
          i, span, a {
            display: block;
          }
        }
        .child {
          li {
            max-width: 115px;
            text-align: center;
            padding: .5rem;
          }
      }
    }
  }
}
  #content {
    margin-left: 100px !important;
  }
}

.content-wrapper {
  .pagination {
    ul {
      margin: auto 0;
    }
  }
}

@media(max-width: 656px)  {
  #page-login {
    .login-header .container {
      display: block !important;
      text-align: center;
      .header-nav {
        margin: 1.5rem 0 1rem !important;
      }
    }
  }
  #sidebar {
    .parent {
      padding: 1rem !important;
      .element {
        span {
          display: none !important;
        }
      }
    }
  }
}
@media(max-width: 621px)  {
  .page-content {
    .content-headline {
      display: block !important;
      text-align: center;
      h1 {
        margin-bottom: 1.5rem !important;
      }
      button {
        width: 100%;
      }
    }
  }
}
@media(min-width: 427px) and (max-width: 556px) {
  #menu-switch {
    vertical-align: -1rem;
    margin-right: 0 !important;
    .menu-username {
      display: none;
    }
    .responsive-switch {
      display: inherit !important;
    }
  }
}
@media(max-width: 426px)  {
  #page-wrapper {
    display: block !important;
    #page-wrapper:before {
      content: none !important;
    }
    #default-header {
      display: block !important;
      padding: 1rem .5rem !important;
      height: auto !important;
      text-align: center;
      #options-dropdown,#accounts-dropdown {
        display: none;
        width: 100% !important;
        &.down {
          top: 135px !important;
          display: block !important;
        }
      }
      .header-nav {
        text-align: center !important;
        #header-alerts, .header-menu {
          display: block !important;
        }
        #header-alerts {
          margin: 0 0 .5rem 0 !important;
          border: 0 !important;
          padding: 0 !important;
        }
        #menu-switch {
          margin: 0 !important;
        }
      }
    }
    #sidebar {
      width: 100% !important;
      max-width: inherit !important;
      float: none !important;
      height: auto !important;
      .sidebar-header {
        height: auto;
        border-bottom: 1px solid $grayscale-3;
        #responsive-menu-btn {
          display: block !important;
          margin: auto;
        }
        .sidebar-logo-wide {
          display: block !important;
          margin: auto;
          width: 100%;
        }
        .sidebar-logo-collapsed {
          display: none !important;
        }
      }
      .parent {
        .element {
          span {
            display: block !important;
          }
        }
      }
      .child {
        li {
          max-width: 100%;
          text-align: center;
          padding: 1rem !important;
        }
      }
    }
    #content {
      margin-left: 0 !important;
    }
  }
}

.filter-option-inner-inner{
  text-align: center;
}
