//   -------------------------------------------------------
//   Links
//   -------------------------------------------------------
body {
  a {
    @include transition();
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  a, button {
    &:focus:not(.focus-none) {
      outline: 2px solid $blue;
      outline-offset: 1.5px;
    }
    &.highlight-hover:hover {
      color: $text-light !important;
    }
  }
}
body {
  .link-as-button,.underline-0 {
    text-decoration: none !important;
  }
  .button-as-link {
    appearance: none;
    background-color: transparent;
    border: none;
  }
}
// ----- Colors.
.link-teal-to-gray {
  color: $teal !important;
  &:hover {
    color: $grayscale-5 !important;
  }
}
.gray-when-disabled {
  &:disabled {
    color: $grayscale-5 !important;
  }
}
.link-gray-to-teal {
  color: $grayscale-5 !important;
  &:hover {
    color: $teal !important;
  }
}
.link-white-to-teal {
  color: white !important;
  &:hover {
    color: $teal !important;
  }
}
.link-white-to-white {
  color: white !important;
}
.link-white-to-gray {
  color: white !important;
  &:hover {
    color: $grayscale-5 !important;
  }
}
.link-red {
  color: $red !important;
}
.link-red-to-gray {
  color: $red !important;
  &:hover {
    color: $grayscale-5 !important;
  }
}
.link-gray-to-red {
  color: $grayscale-5 !important;
  &:hover {
    color: $red !important;
  }
}
// ----- Effects.
.go-to-icon-right {
  svg,i {
    @include transition();
    margin-left: 1rem;
  }
  &:hover {
    svg,i {
      margin-left: 1.5rem !important;
    }
  }
}
.go-to-icon-left {
  svg,i {
    @include transition();
    mmargin-right: .5rem;
  }
  &:hover {
    svg,i {
      margin-right: .5rem !important;
    }
  }
}
//   -------------------------------------------------------
//   Buttons
//   -------------------------------------------------------
@mixin button {
  @include transition(background, .5s, ease-in-out);
  @include change-font();
  cursor: pointer;
  letter-spacing: .03rem;
  text-align: center;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  border: 1.5px solid transparent;
  border-radius: .15rem;
  background-position: center;
  &:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
}
.cec-btn {
  @include button;
  font-size: .85rem;
  padding: .45rem .9rem;
}
.cec-btn-small {
  @include button;
  font-size: .75rem;
  padding: .25rem .45rem;
}
.cec-btn-primary-teal {
  color: white !important;
  background: $teal;
  border-color: $teal;
  &:hover {
    background: $teal-dark radial-gradient(circle, transparent 1%, $teal-dark 1%) center/15000%;
    border-color: transparent;
  }
  &:active {
    background-color: $teal-soft;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-secondary-teal {
  color: $teal !important;
  background: white;
  border-color: $teal;
  &:hover {
    color: white !important;
    background: $teal radial-gradient(circle, transparent 1%, $teal 1%) center/15000%;
    border-color: transparent!important;
  }
  &:active {
    background-color: $teal-soft;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-soft-teal {
  color: $teal !important;
  background: $teal-soft;
  border-color: $teal-soft;
  &:hover {
    color: white !important;
    background: $teal radial-gradient(circle, transparent 1%, $teal 1%) center/15000%;
    border-color: transparent!important;
  }
  &:active {
    background-color: $teal-soft;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-white {
  color: $grayscale-5 !important;
  background: white;
  border-color: white;
  &:hover {
    background: $grayscale-3 radial-gradient(circle, transparent 1%, $grayscale-3 1%) center/15000%;
    border-color: $grayscale-3 !important;
  }
  &:active {
    background-color: white;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-primary-red {
  color: white !important;
  background: $red;
  border-color: $red;
  &:hover {
    background: $red-dark radial-gradient(circle, transparent 1%, $red-dark 1%) center/15000%;
    border-color: transparent;
  }
  &:active {
    background-color: $red-soft;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-secondary-red {
  color: $red !important;
  background: white;
  border-color: $red;
  &:hover {
    color: white !important;
    background: $red radial-gradient(circle, transparent 1%, $red 1%) center/15000%;
    border-color: transparent!important;
  }
  &:active {
    background-color: $red-soft;
    background-size: 100%;
    transition: background 0s;
  }
}
.cec-btn-transparent-red {
  color: $red !important;
  background: none;
  border: 0;
  padding: 0;
  box-shadow: none;
  &:hover {
    color: $notifications-error !important;
    box-shadow: none;
  }
  &:active {
    color: $red-soft !important;
    box-shadow: none;
  }
}
.cec-btn:disabled {
  cursor: initial !important;
  color: $grayscale-5 !important;
  background: $grayscale-2 !important;
  border-color: $grayscale-2 !important;
  box-shadow: none;
}
.cec-btn-loading {
  position: relative;
  span {
    opacity: 0.4;
  }
  svg {
    color: #009494;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
.not-allowed {
  cursor: not-allowed;
}
