//   -------------------------------------------------------
//   Table
//   -------------------------------------------------------
.row-hover {
  @include transition();
  &:hover, &.active {
    background-color: rgba(0, 0, 0, 0.075);
  }
}
.table-top {
  display: flex;
  margin-bottom: 1.5rem;
  .headline {
    h5 {
      font-size: 1.15rem;
      font-weight: bold;
    }
  }
  .headline.with-border {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #DEE2E6;
    min-height: 55px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .filter {
    margin: auto 0;
    display: flex;
    .sort-by {
      float: left;
      margin-top: .4rem;
      color: $text-light;
      &:last-of-type {
        margin-left: 2rem;
      }
    }
    .dropdown {
      margin-left: 1rem;
      .dropdown-toggle {
        display: flex;
        height: 38px;
        min-width: 172px;
        width: fit-content;
        border: 1px solid #4A4A49;
        border-radius: 2px;
        background: transparent;
        color: $grayscale-5;
        text-align: left;
        &:hover,&:active,&:focus {
          background: transparent !important;
        }
        &:after {
          margin: auto 0 auto auto !important;
        }
      }
      .dropdown-item {
        white-space: inherit !important;
      }
      .dropdown-menu {
        width: 100%;
      }
    }
    .academic-year {
      .dropdown-toggle {
        min-width: 225px;
      }
    }
  }
}
.search.no-margin-top {
  margin: 0 0 auto auto !important;
}
.search {
  margin: auto 0 auto auto;
  .search-container {
    position: relative;
    border: 1px solid #4A4A49;
    border-radius: 2px;
    padding: 0 0 0 1rem;
    height: 38px;
    max-width: 250px;
    label {
      position: absolute;
      left: 10px;
      top: 7px;
      bottom: 0;
      margin: auto 0;
      color: $teal;
    }
    input {
      @include transition();
      border: none;
      height: 38px;
      max-width: 255px;
      width: 100%;
      background: transparent;
      padding-left: 1rem;
      &:focus, &:active {
        outline: none;
        -moz-outline-style: none;
        margin-left: .5rem;
        padding-right: 1rem;
      }
    }
  }
}
.table-bottom {
  display: flex;
  margin-top: 1.5rem;
  .entries {
    margin: auto 0;
    color: $text-light;
  }
  .pagination {
    margin: auto 0 auto auto;
    #cec-pagination--container {
      li {
        @include transition();
        &:hover {
          background-color: $grayscale-5;
          color: white;
        }
      }
      li[aria-selected="true"] {
        background: $grayscale-5;
        color: white;
      }
      #cec-pagination--item-previous, #cec-pagination--item-next {
        border-radius: 50%;
        &:hover {
          background-color: $bg-light !important;
        }
      }
    }
  }
}
.table-content {
  th, td {
    vertical-align: middle !important;
  }
  thead {
    .sortable {
      @include transition();
      cursor: pointer;
      position: relative;
      &:hover {
        color: $teal;
      }
      svg {
        position: absolute;
        width: 20px !important;
        height: 20px !important;
        margin-top: .15rem;
        margin-left: .15rem;
      }
    }
    .sortable.with-help {
      .help-container {
        display: flex;
        .icons {
          svg {
            position: inherit !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
  .narrow {
    th {
      text-align: center;
    }
  }
  tbody {
    .delete-item {
      button {
        background: transparent;
        border: none;
        color: $grayscale-3;
        &:hover {
          color: $red;
        }
      }
      svg:hover {
        cursor: pointer;
        fill: $red;
      }
    }
  }
  td.collapse-block.off {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.table-content-compassplus {
  th, td {
    vertical-align: middle !important;
  }
  thead {
    top: -1px;
    z-index: 3;
    th {
      background-color: white;
      position: sticky;
      top: -1px;
    }

    tr {
      height: 67px;
    }

    .sortable {
      @include transition();
      cursor: pointer;
      position: relative;
      &:hover {
        color: $teal;
      }
      svg {
        position: absolute;
        width: 20px !important;
        height: 20px !important;
        margin-top: .15rem;
        margin-left: .15rem;
      }
    }
    .sortable.with-help {
      .help-container {
        display: flex;
        .icons {
          svg {
            position: inherit !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
  .narrow {
    th {
      text-align: center;
    }
  }
  tbody {
    .delete-item {
      button {
        background: transparent;
        border: none;
        color: $grayscale-3;
        &:hover {
          color: $red;
        }
      }
      svg:hover {
        cursor: pointer;
        fill: $red;
      }
    }
  }
  td.collapse-block.off {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.sticky-table-content-compassplus {
  position: relative;
  overflow: auto;
  width: 100%;
  max-height: 800px;

  .table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    .isLastFixed {
      border-right: 2px solid $grayscale-2;
    }

    th, td {
      vertical-align: middle !important;
    }

    thead {
      th {
        position: sticky;
        top: -1;
      }

      .sortable {
        @include transition();
        cursor: pointer;
        position: relative;
        &:hover {
          color: $teal;
        }
        svg {
          position: absolute;
          width: 20px !important;
          height: 20px !important;
          margin-top: .15rem;
          margin-left: .15rem;
        }
      }
      .sortable.with-help {
        .help-container {
          display: flex;
          .icons {
            svg {
              position: inherit !important;
              margin: 0 !important;
            }
          }
        }
      }
    }

    tbody {
      tr:hover td {
        color: #212529;
        background-color: #ececec !important;
      }

      .delete-item {
        button {
          background: transparent;
          border: none;
          color: $grayscale-3;
          &:hover {
            color: $red;
          }
        }
        svg:hover {
          cursor: pointer;
          fill: $red;
        }
      }
    }

    td.collapse-block.off {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.actions-list {
  ul {
    display: flex;
    align-items: center;
  }
}

.actions-list {
  li {
    list-style: none;
    display: inline-block;
    svg {
      width: 20px !important;
      height: 20px !important;
      margin: 0 .25rem;
    }
    a {
      color: $grayscale-5;
      &:hover {
        color: $teal;
      }
    }
  }
  .delete a:hover {
    color: $red !important;
  }
  .disabled {
    color: $grayscale-3;
    cursor: default;
  }
  .action-text-link {
    border-left: 1px solid $grayscale-3;
    padding-left: .5rem;
    margin-left: .5rem;
  }
}
//   -------------------------------------------------------
//   Status & Attainment
//   -------------------------------------------------------
.status-label {
  svg {
    width: 18px;
    height: 18px;
  }
}
.status-label.status-completed {
  svg {
    fill: $teal;
  }
}
.status-label.status-no {
  svg {
    fill: $red;
  }
}
.status-label {
  &.status-progress,&.status-done,&.status-pending,&.status-cancelled,&.status-offered-unsure,&.status-offered-absent {
    span {
      display: block;
      width: 105px;
      padding: .25rem .5rem;
      color: white;
      border-radius: 25px;
      text-align: center;
    }
  }
  &.status-with-percent {
    span {
      width: 145px;
    }
  }
  &.status-progress,&.status-pending {
    span {
      background: $yellow;
    }
  }
  &.status-done {
    span {
      background: $teal-dark;
    }
  }
  &.status-offered-unsure {
    span {
      background: $red-soft;
    }
  }
  &.status-offered-absent {
    span {
      background: $red;
    }
  }
  &.status-cancelled {
    span {
      background: $grayscale-5;
    }
  }
}
//   -------------------------------------------------------
//   Responsive
//   -------------------------------------------------------
@media(max-width: 1270px)  {
  .table-top {
    flex-direction: column;
    .filter {
      margin: 0 auto;
      padding-bottom: 5px;
    }
    .search {
      margin: 1rem auto 0 auto;
    }
  }
}
@media(max-width: 992px)  {
  .table-content {
    .sortable {
      svg {
        margin-left: -.15rem !important;
      }
    }
  }
  .table-top {
    display: block !important;
    text-align: center;
    .filter {
      margin-bottom: 1.25rem !important;
      flex-direction: column;
      width: 100%;
      .sort-by {
        float: none !important;
        margin: 0 !important;
        margin-top: 1rem !important;
      }
      .dropdown {
        margin: .75rem 0 0 0 !important;
        width: 100%;
        .dropdown-menu {
          width: 100%;
        }
        .dropdown-toggle {
          width: 100% !important;
        }
      }
    }
    .search input {
      float: left;
      &:focus, &:active {
        margin-left: 0 !important;
      }
    }
  }
  .table-bottom {
    display: grid !important;
    .entries {
      margin: 0 auto 1.25rem auto !important;
    }
    .pagination {
      margin: 0 auto !important;
    }
  }
}
@media(max-width: 769px)  {
  .table-content {
    th {
      text-align: center;
    }
    .actions-list {
      .action-text-link {
        border: 0 !important;
        padding: 0 !important;
        margin: .5rem 0 0 0 !important;
      }
    }
  }
}
@media(max-width: 426px) {
  .table-top {
    .filter {
      width: 100%;
    }
    .dropdown {
      width: 100%;
    }
    .search {
      input {
        float: left;
      }
    }
  }
}
