/* Global */
.page.page--section {
  background: #edf2f7;
  .page__header, .page__footer {
    .page__container {
      padding: 1rem;
      @media (min-width: 1024px) {
        padding: 1rem 2.5rem;
      }
    }
  }
}
/* Header */
.page.page--section {
  .page__header {
    border-bottom: 1px solid #e2e8f0;
  }
}
/* Footer */
.page.page--section {
  .page__footer {
    background: white;
    font-size: .95rem;
    color: $text-light;
  }
}
/* Content */
.page.page--section {
  .page__content {
    background: #edf2f7;
  }
  .section__block {
    width: 100%;
    background: white;
    border-bottom: 1px solid #e2e8f0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem;
    @media (min-width: 769px) {
      border-radius: .5rem;
      padding: .5rem;
    }
    @media (min-width: 1025px) {
      padding: 2.5rem;
    }
    @media (min-width: 1441px) {
      padding: 3rem;
    }
    .padding--negative {
      margin: auto -.5rem;
      padding-left: .5rem;
      padding-right: .5rem;
      @media (min-width: 1025px) {
        margin: auto -2.5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
      @media (min-width: 1441px) {
        margin: auto -3rem;
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}
