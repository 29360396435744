//   -------------------------------------------------------
//   Mixins for cross-projects
//   -------------------------------------------------------
@mixin label {
  color: $grayscale-5;
  font-size: .95rem;
}
@mixin input {
  @include transition();
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $grayscale-4;
  border: 1px solid $grayscale-3;
  padding: 0 0 0 3rem;
  border-radius: .15rem;
}
//   -------------------------------------------------------
//   Inputs type: text
//   -------------------------------------------------------
input[autocomplete="off"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
input, textarea {
  &:focus, &:active {
    border-color: $focus-input-border !important;
    outline: none;
    -moz-outline-style: none;
  }
}
textarea {
  min-height: 250px !important;
}
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.text-underline {
  text-decoration: underline !important;
}
.mini-textarea {
  textarea {
    min-height: 100px !important;
  }
}
input:disabled {
  background: #e9ecef;
}
::-webkit-input-placeholder {
  color: $grayscale-3;
}
::-moz-placeholder {
  color: $grayscale-3;
}
:-ms-input-placeholder {
  color: $grayscale-3;
}
:-moz-placeholder {
  color: $grayscale-3;
}
.default-form {
  .input-label {
    @include label();
    margin-bottom: 1rem;
  }
  .required {
    &:after {
      content: '*';
      color: $red;
      margin-left: .5rem;
    }
  }
  .default-input {
    @include input();
  }
  .input-icon {
    position: absolute;
    left: .85rem;
    text-align: center;
    color: $grayscale-3;
  }
  svg.input-icon {
    top: 3.05rem;
    width: 20px;
    height: 20px;
    &.without-label {
      top: .6rem;
    }
  }
  i.input-icon {
    font-size: 1.25rem;
    top: 2.7rem;
  }
  .form-group {
    margin: 1rem 0 !important;
    .form-block {
      padding: 0;
    }
  }
}
.label-with-help {
  display: flex;
  width: 100%;
  .help-icon {
    margin: auto 0 auto auto;
    border-radius: 50%;
    background: $teal;
    color: white;
    width: 20px;
    height: 20px;
    line-height: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.form-col-6 {
  .form-block:first-child {
    padding-right: 1rem !important;
  }
  @media(max-width: 576px)  {
    .form-block:first-child {
      padding-right: 0 !important;
    }
    .form-block:last-child {
      margin-top: .8rem !important;
    }
  }
}
.form-error {
  display: flex;
  width: 100%;
  color: $red;
  margin-top: .5rem;
  p {
    margin: auto 0;
    font-style: italic;
    color: $red !important;
  }
  .icon-error {
    margin: auto 0 auto auto;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.character-count {
  display: flex;
  width: 100%;
  color: $grayscale-3;
  margin-top: .5rem;
  justify-content: flex-end;
  p {
    margin: auto 0;
    font-style: italic;
  }
}
//   -------------------------------------------------------
//    Fake labels for checkboxes
//   -------------------------------------------------------
.float-checkbox {
  text-align: left;
  display: flex;
  .pretty {
    margin: auto 0;
  }
  .float-label {
    padding-left: 1.5rem;
    margin: 0;
  }
}

//   -------------------------------------------------------
//    Multiline Label wrapping for checkboxes
//   -------------------------------------------------------
.wrap-label {

  .pretty {
    white-space: inherit;
    width: 100%;
  }

  .pretty .state label{
    text-indent: 0;
    padding-left: 2rem;
  }

  .pretty .state label:after,
  .pretty .state label:before,
  .pretty.p-icon .state .icon {
    top: 0;
  }
}


.modal-content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}


//   -------------------------------------------------------
//    React Select Changes
//   -------------------------------------------------------
.custom-react-select {
  .input-icon {
    left: 1.8rem;
  }
  .custom-select__value-container {
    padding-left: 3rem;
  }
}
.custom-react-select-inline {
  .form-block {
    display: flex;
    label {
      margin-bottom: 0 !important;
      line-height: inherit;
      min-width: 25%;
      @media(min-width: 856px) and (max-width: 1025px) {
        min-width: 40% !important;
      }
      @media(min-width: 556px) and (max-width: 855px) {
        min-width: 50% !important;
      }
      @media (min-width: 1440px) {
        line-height: 38px;
      }
    }
    @media(max-width: 555px) {
      display: block !important;
    }
  }
}
.custom-react-select,.custom-react-select-inline {
  .default-input {
    height: inherit !important;
    padding-left: 0 !important;
  }
  .custom-select__placeholder{
    color: $grayscale-3;
  }
  .custom-select__control {
    min-height: 38px;
    height: 100%;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0 0 0 1px $grayscale-3;
  }
  .custom-select__control--is-focused {
    height: 100%;
    box-shadow: 0 0 0 1px black !important;
  }
  .custom-select__single-value {
    padding: .25rem .85rem;
    font-size: .85rem;
    background: $teal !important;
    color: white !important;
    border-radius: .25rem;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    width: fit-content;
  }
  .custom-select__multi-value__label,
  .custom-select-add-provider__multi-value__label {
    padding: .25rem .85rem;
    font-size: .85rem;
    background: $teal !important;
    color: white !important;
    border-radius: .25rem 0 0 .25rem;
    -webkit-box-shadow: 0 .125rem 0.25rem rgba(0, 0, 0, .075);
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  }
  .custom-select__multi-value__remove {
    background: $teal;
    border-radius: 0 .25rem .25rem 0;
  }
}
.custom-react-select.no-padding {
  .custom-select__value-container {
    padding-left: 1rem !important;
  }
  @media(max-width: 555px) {
    .col-12 {
      margin-bottom: 1rem;
    }
    .col-12:last-child {
      margin-bottom: 0 !important;
    }
  }
}
.custom-react-select.no-color {
  .custom-select__single-value {
    background: transparent !important;
    color: $grayscale-4 !important;
    box-shadow: none;
    padding: 0;
    font-size: 1rem;
  }
}
//   -------------------------------------------------------
//    React Select Changes - Grouped Options
//   -------------------------------------------------------
.grouped-options-heading {
  background: transparent;
  color: white;
  padding: 1rem;
  border-bottom: 1px solid #DEE2E6;
  font-size: .85rem;
}
.grouped-option {
  position: relative;
  .label {
    .custom-select__option {
      padding-left: 1rem;
    }
  }
  .students-number {
    position: absolute;
    right: 1rem;
    top: .5rem;
    border-radius: 20px;
    min-width: 55px;
    background: $bg-light;
    text-align: center;
    padding: .15rem;
    font-size: .85rem;
    svg {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      margin-right: .25rem;
    }
  }
}

.advice-fake-option {
  cursor: default;
  margin-top: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-style: italic;
}

//   -------------------------------------------------------
//    Autocomplete Styles
//   -------------------------------------------------------
.autocomplete__input {
  .autocomplete__menu {
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 0;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    -webkit-overflow-scrolling: touch;
    .autocomplete__item {
      color: inherit;
      cursor: default;
      display: block;
      font-size: inherit;
      padding: 8px 12px;
      width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      box-sizing: border-box;
      &.highlighted {
        background-color: #B5DDDD;
      }
    }
  }
}
//   -------------------------------------------------------
//   Viewers styles
//   -------------------------------------------------------
.no-clear-viewers {
  .custom-select__multi-value__label {
    border-radius: .25rem;
  }
  .custom-select__multi-value__remove {
    display: none;
  }
}
//   -------------------------------------------------------
//   Draggable Items
//   -------------------------------------------------------
.draggable {
  .drag-block {
    display: flex;
    margin-bottom: 1rem;
    label {
      margin-bottom: 0 !important;
      line-height: 38px;
      min-width: 25%;
    }
    .drag-input {
      position: relative;
      width: 100%;
      padding-right: 45px;
      .drag-icon {
        position: absolute;
        left: -27px;
        top: 8px;
        cursor: grab;
        &:hover {
          color: $teal;
        }
      }
      .drag-right {
        position: absolute;
        right: 0;
        top: 8px;
      }
      .drag-inner {
        position: absolute;
        right: 53px;
        top: 8px;
      }
    }
  }
  .drag-block:last-child {
    margin-bottom: 0 !important;
  }
}
.preferred-inputs .preferred-expanded {
  padding: 2rem 0;
  margin: 0 -32px 1rem -32px;
  background: $bg-light;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  .default-form {
    padding: 0 2rem;
  }
  .add-button {
    padding: 0 2rem 0;
  }
}
.gu-mirror {
  display: none !important;
}
//   -------------------------------------------------------
//    Filter - Checkboxes for search inputs
//   -------------------------------------------------------
.filter-input-search-container {
  .filter-by,.filter-input-search {
    display: inline;
  }
  .filter-by {
    background: $bg-light;
    color: $text-light;
    padding: .5rem 1rem;
    border-radius: .25rem;
    margin-right: .5rem;
    font-size: .75rem;
  }
  .filter-input-search {
    color: $text-light;
    font-size: .75rem;
  }
}
//   -------------------------------------------------------
//   Inline inputs
//   -------------------------------------------------------
.inline-form {
  .form-block {
    display: flex;
    .input-label {
      margin-bottom: 0 !important;
      line-height: 38px;
      min-width: 25%;
      @media(min-width: 856px) and (max-width: 1025px) {
        min-width: 40% !important;
      }
      @media(min-width: 556px) and (max-width: 855px) {
        min-width: 50% !important;
      }
    }
    .default-input {
      padding-left: 1rem !important;
    }
    .checkboxes {
      line-height: 38px;
    }
    @media(max-width: 555px) {
      display: block !important;
    }
  }
}
// We need to do this in order to fix text inputs at expanded information of preferred options.
.default-input.fixed-preferred-input {
  padding-left: 1rem !important;
  height: 39px !important;
}
//   -------------------------------------------------------
//   Z-index
//   -------------------------------------------------------
.custom-select__menu {
  z-index: 50 !important;
}
