.cec-nav {
  border: none !important;
  width: 100%;
  .nav-item {
    margin-bottom: 0 !important;
    margin-right: 1rem;
    a {
      position: relative;
      border: none !important;
      cursor: pointer;
      padding-bottom: 2rem;
      &:hover {
        color: $teal;
      }
      &:after {
        @include transition();
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $teal;
        transform: scaleX(0);
      }
    }
    .active {
      color: $teal;
      &:after {
        transform: scaleX(1);
      }
    }
  }
  .nav-button {
    margin: 0 0 auto auto;
  }
}
.tab-content {
  .setting-section {
    .headline {
      display: flex;
      border-bottom: 1px solid #DEE2E6;
      min-height: 55px;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      h5 {
        font-size: 1.15rem;
        font-weight: bold;
      }
    }
  }
}

.btn-return {
    position: fixed;
    right: 10px;
    bottom: 60px;
    padding: 0 .45rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px $blue;
    }

    &:focus, &:focus:hover {
        border: 1px solid $bg-light;
    }
}

//   -------------------------------------------------------
//   Responsive
//   -------------------------------------------------------
.cec-nav {
  @media(max-width: 1050px) and (min-width: 991px) {
    .nav-item {
      margin: 0 !important;
      font-size: .85rem;
    }
  }
  @media(max-width: 992px) {
    display: block !important;
    text-align: center;
    .nav-item {
      margin-right: 0 !important;
    }
  }
}
