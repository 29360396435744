#cltp {
  html, body, #root, .page {
    height: 100%;
    width: 100%;
    display: flex;
  }
  html {
    overflow-x: hidden;
  }
  .page {
    flex-direction: column;
  }
}
.button-link {
  background: transparent;
  padding: 0;
  border: none;
  text-decoration: underline;
}
.button-link:disabled {
  cursor: not-allowed;
}
.cec-btn-loading {
  position: relative;
  span {
    opacity: 0.4;
  }
  svg {
    color: #009494;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
/* Global Header */
.page {
  .page__header {
    background: white;
    .page__container {
      display: block;
      @media (min-width: 576px) {
        display: flex;
      }
      .header__logo {
        width: 100px;
        height: auto;
      }
    }
  }
}
/* Global Page Container */
.page {
  .page__container {
    width: 100%;
    padding: 1.5rem 0;
    margin: 0 auto;
    @media (min-width: 769px) {
      padding: 1.5rem;
    }
    @media (min-width: 1025px) {
      width: 95%;
      padding: 2.5rem 0;
    }
  }
  .page__footer {
    margin-top: auto;
  }
}
/* Global Loader */
.loader__container {
  display: flex;
  width: 100%;
  height: 100%;
  .loader__progress {
    margin: auto;
  }
}
/* Spin Animation */
.animation--spin {
  @include transition();
  animation: spin infinite linear 1000ms;
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
/* Failed Payment Processes More Info */
.more-info--entry-list {
  .more-info--entry-index,.more-info--entry-message,.more-info--entry-data {
    font-size: .85rem;
  }
  .more-info--entry-index {
    float: left;
    font-weight: 900;
    margin-right: .25rem;
  }
  .more-info--entry-message {
    margin-bottom: .5rem !important;
  }
  .more-info--entry-data {
    position: relative;
    padding-left: 50px;
    font-style: italic;
    &:before {
      content: '';
      position: absolute;
      height: 50%;
      width: 25px;
      left: 15px;
      border-left: 2px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    .more-info--object-key {
      font-weight: bold;
    }
    .more-info--array {
      .more-info--value {
        display: block;
      }
    }
  }
  .more-info--entry {
    margin-bottom: 0.6rem;
  }
}
.more-info--entry-data > * .more-info--object, .more-info--array {
  margin-left: 1rem;
}
.more-info--entry {
  position: relative;
  cursor: pointer;
  &:after {
    font: normal normal normal #{$mdi-font-size-base}/1 '#{$mdi-font-name}';
    content: "\F0374";
    position: absolute;
    top: 1px;
    left: -1rem;
    font-size: 100%;
  }
  .more-info--entry-data {
    cursor: auto;
  }
}
.more-info--entry.collapsed {
  .more-info--entry-data {
    display: none;
  }
  &:after {
    content: "\F0415";
  }
}
/* Field Error Entry List */
.field-error--entry-list {
  .field-error--entry-index,.field-error--entry-message,.field-error--entry-data {
    font-size: .85rem;
  }
  .field-error--entry-index {
    float: left;
    font-weight: 900;
    margin-right: .25rem;
  }
  .field-error--entry-message {
    margin-bottom: .5rem !important;
  }
  .field-error--entry-data {
    position: relative;
    padding-left: 50px;
    font-style: italic;
    &:before {
      content: '';
      position: absolute;
      height: 50%;
      width: 25px;
      left: 15px;
      border-left: 2px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    .field-error--object-key {
      font-weight: bold;
    }
    .field-error--array {
      .field-error--value {
        display: block;
      }
    }
  }
  .field-error--entry {
    margin-bottom: 0.6rem;
  }
}
.field-error--entry-data > * .field-error--object, .field-error--array {
  margin-left: 1rem;
}
.field-error--entry {
  position: relative;
  cursor: pointer;
  &:after {
    font: normal normal normal #{$mdi-font-size-base}/1 '#{$mdi-font-name}';
    content: "\F0374";
    position: absolute;
    top: 1px;
    left: -1rem;
    font-size: 100%;
  }
  .field-error--entry-data {
    cursor: auto;
  }
}
.field-error--entry.collapsed {
  .field-error--entry-data {
    display: none;
  }
  &:after {
    content: "\F0415";
  }
}
.filter-scrollable-options {
  max-height: 21em;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background: #009494;
    border-radius: 4px;
    height: 3.5em;
  }
}

