.table {
  margin-bottom: 0;
  border-spacing: 0;
  border: 1px solid #dee2e6;
  display: block;
  overflow-x: auto;
  min-width: inherit !important;
  .table__row {
    &:last-child {
      .body__cell {
        border-bottom: 0;
      }
    }
  }
  .cell--actions {
    min-width: 120px;
  }
  .body__cell.cell--actions, .head__cell.cell--actions {
    display: flex;
    .actions__more {
      opacity: 0;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0;
      pointer-events: none;
      .more__icon {
        width: 18px;
        height: 18px;
        margin: 0 .5rem;
      }
    }
  }
  .head__cell, .body__cell {
    position: relative;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    &:last-child {
      border-right: 0;
    }
    .resizer {
      @include transition(0);
      display: inline-block;
      background: rgba($grayscale-5, .35);
      width: 5px;
      height: 100%;
      position: absolute;
      right: 3px;
      top: 0;
      transform: translateX(50%);
      z-index: 0;
      touch-action:none;
      &.isResizing {
        background: $teal;
      }
    }
  }
  .head__cell {
    display: flex;
    align-items: center;
    background: #e9ecef;
    border-bottom: 2px solid #dee2e6;
    overflow: visible;
    .cell__text {
      font-size: .95rem;
    }
    .cell__filter {
      margin-left: auto;
    }
    .cell__sort , .cell__filter {
      cursor: pointer;
    }
    .cell__filter {
      .filter__container {
        position: relative;
        .filter__active {
          position: absolute;
          z-index: 30;
          right: 0;
          bottom: 3px;
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background: $blue;
          border: 1px solid white;
        }
        .filter__icon {
          @include transition();
          color: $grayscale-3;
          &:hover, &.active {
            color: $teal;
          }
        }
      }
    }
    .cell__sort {
      .sort__container {
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        .sort_order {
          position: absolute;
          bottom: 15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
          font-size: .75rem;
          color: $grayscale-3;
        }
      }
    }
  }
  .body__cell {
    background: white;
  }
}

.table__loading {
  pointer-events: none;
  user-select: none;
  overflow-y: hidden;

  .table__head {
    div {
      opacity: 0.5;
    }
  }

  .table__body {
    position: relative;
    div:not(.table__loader) {
      opacity: 0.5;
    }
  }

  .table__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    margin: auto;
  }

  .table__loader:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23009494" viewBox="0 0 24 24"><path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" /></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    content: "";
    @include transition();
    animation: spin infinite linear 1000ms;
  }
}

.actions__more.show {
  opacity: 1 !important;
  position: static !important;
  transition: all 0.3s ease-in;
  pointer-events: auto;
}

.actions__more--checkboxes {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  opacity: 0.3;
}

.actions__more--checkboxes.show {
  opacity: 1 !important;
  transition: all 0.2s ease-in;
}

.y-scrollable-menu {
  max-height: 280px;
  overflow-y: auto;
}

.header-with-help {
  display: flex;
  width: 100%;
  .help-icon {
    margin: auto 0.25em auto auto;
    border-radius: 50%;
    background: $teal;
    color: white;
    width: 15px;
    height: 15px;
    line-height: 16px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
