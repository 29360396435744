//   -------------------------------------------------------
//   Double scroll
//   -------------------------------------------------------
$height:20px;
.fakeWrapper {
  height: $height;
  overflow-x: auto;
  overflow-y: hidden;
}
.fakeContent {
  height: $height;
}
