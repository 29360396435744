/*----- Global CEC's styles -----*/
@import "parent/app";
/*----- App styles -----*/
@import "components/layout";
@import "components/form";
@import "components/table";
@import "components/tabs";
@import "components/impersonation";
/*----- Pages -----*/
@import "pages/login";
@import "pages/section";
