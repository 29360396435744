//   -------------------------------------------------------
//   Cohorts Pills
//   -------------------------------------------------------
.cohort-type {
  border-radius: 25px;
  padding: .5rem 1rem;
  color: white;
  background: transparent;
  font-size: .85rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  text-align: center;
}
#selection-table {
  .cohort-type {
    display: inline-block;
  }
}
// ----- Cohort type colors
.custom, .custom_group {
  background: $custom;
}
.year, .year_group {
  background: $year;
}
.characteristics, .mis_group {
  background: $characteristics;
}
.form, .registration_group {
  background: $form;
}
.students, .student {
  background: $student;
  &.custom-select_multi-value__container {
    background: inherit;
  }
}
.subject, .subject_group,
.teaching, .teaching_group {
  background: $subject;
}
// ----- Old Cohorts
.cohort--old {
  color: $grayscale-4;
  &.custom, &.custom_group {
    background: rgba($custom, .25);
  }
  &.year, &.year_group {
    background: rgba($year, .25);
  }
  &.characteristics, &.mis_group {
    background: rgba($characteristics, .25);
  }
  &.form, &.registration_group {
    background: rgba($form, .25);
  }
  &.students, &.student {
    background: rgba($student, .25);
    &.custom-select_multi-value__container {
      background: inherit;
    }
  }
  &.subject, &.subject_group,
  &.teaching, &.teaching_group {
    background: rgba($subject, .25);
  }
}
//   -------------------------------------------------------
//   Groups Table
//   -------------------------------------------------------
.table-content {
  @media(max-width: 1400px) {
    .cohort-type {
      display: block;
      text-align: center;
    }
  }
}
//   -------------------------------------------------------
//   Groups List
//   -------------------------------------------------------
.cohorts-list {
  line-height: 2.5rem;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 1rem;
  }
}
//   -------------------------------------------------------
//   New group form - Group Members - Search filters
//   -------------------------------------------------------
#custom-group-alias-block {
  .form-group {
    margin: 0 0 2rem 0 !important;
  }
}
#custom-group-members-block {
  .input-icon {
    top: .7rem !important;
  }
}
#custom-group-members-block, #cohorts-block {
  .grouped-options-heading {
    &.heading-custom, &.heading-custom_group {
      background: $custom;
    }
    &.heading-year, &.heading-year_group {
      background: $year;
    }
    &.heading-characteristics, &.heading-mis_group {
      background: $characteristics;
    }
    &.heading-form, &.heading-registration_group {
      background: $form;
    }
    &.heading-student, &.heading-students {
      background: $student;
      color: $grayscale-5;
    }
    &.heading-subject, &.heading-subject_group,
    &.heading-teaching, &.heading-teaching_group {
      background: $subject;
    }
  }
  .custom-select_multi-value__container svg:hover {
    fill: white !important;
  }
  .custom-select__multi-value__remove:hover {
    color: white !important;
  }
  .custom-select_multi-value__container.label-custom_group {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $custom !important;
      svg {
        fill: white !important;
      }
    }
  }
  .custom-select_multi-value__container.label-year_group {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $year !important;
    }
  }
  .custom-select_multi-value__container.label-mis_group {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $characteristics !important;
    }
  }
  .custom-select_multi-value__container.label-registration_group {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $form !important;
    }
  }
  .custom-select_multi-value__container.student {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $student !important;
      color: $grayscale-5 !important;
    }
  }
  .custom-select_multi-value__container.label-subject_group, .custom-select_multi-value__container.label-teaching_group {
    .custom-select__multi-value__label, .custom-select__multi-value__remove {
      background: $subject !important;
    }
  }
  .custom-select_multi-value__container {
    .custom-select__multi-value__remove:hover {
      background: $red-soft !important;
    }
  }
  .custom-select_multi-value__container.highlighted {
    border: 2px solid $teal;
  }
  .editable {
    padding: .25rem 0 .25rem .85rem !important;
    .value-edit {
      svg {
        @include transition();
        opacity: .5;
        width: 18px;
        height: 18px;
        margin: -.15rem .5rem 0 .5rem;
        &:hover {
          cursor: pointer;
          opacity: .75;
        }
      }
    }
  }
  .student .attendance svg {
    pointer-events: none;
    width: 18px;
    height: 18px;
    margin: 0 0.25rem 0 -0.5rem;
  }
}
