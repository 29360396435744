//   -------------------------------------------------------
//   SelectPicker
//   -------------------------------------------------------
.bootstrap-select {
  .dropdown-item.active, .dropdown-item:active {
    background-color: $teal;
  }
  .dropdown-toggle {
    background: transparent;
    height: 38px;
    border: 1px solid #4A4A49;
    border-radius: 2px;
    color: #252524;
  }
  .filter-option {
    color: #252524 !important;
  }
  .filter-option-inner-inner {
    text-align: left !important;
  }
}

.cohort-form-modal {
  .dropdown {
    .dropdown-toggle {
      display: flex;
      height: 38px;
      min-width: 172px;
      width: fit-content;
      border: 1px solid $grayscale-3;
      border-radius: 2px;
      background: transparent;
      color: $grayscale-4;
      text-align: left;
      &:hover,&:active,&:focus {
        background: transparent !important;
      }
      &:after {
        margin: auto 0 auto auto !important;
      }
    }
    .dropdown-item {
      white-space: inherit !important;
    }
  }
}
