// ----- Primary.
$teal:#009494;
$teal-dark:#008080;
$teal-very-dark:#006C6C;
$teal-soft:rgba(0,148,148,0.15);
$teal-very-soft:rgba(0,148,148,0.05);
$teal-light:#11bbbb;
// ----- Secondary.
$red:#EC5F65;
$red-dark: #bb4a4f;
$red-soft:#F08481;
$red-very-soft:#F8C6C0;
$orange:#ED6E4F;
$yellow:#E8B463;
$yellow-dark: #EFC01E;
$yellow-dark-text: #856404;
$yellow-soft: #ffeeba;
$yellow-very-soft: #fff3cd;
$green:#009269;
$blue:#1496C4;
$blue-soft:rgba(20,150,196,0.15);
$blue-very-soft:rgba(20,150,196,0.05);
$blue-dark:#006992;
$purple:#6E52A1;
$purple-dark:#525E93;
// ----- Notifications.
$notifications-error:#D6443C;
$notifications-success:#08A84E;
// ----- Grayscale.
$grayscale-1:#F1F1F1;
$grayscale-2:#DDDDDD;
$grayscale-3:#A7A7A6; // For borders & Light texts.
$grayscale-4:#393938; // For normal texts.
$grayscale-5:#252524; // For headlines.
// ----- Dark Sidebar.
$sidebar:#222221;
$sidebar-light:#363635;
// ----- Other.
$shadow:rgba(0, 0, 0, 0.25);
$bg-light:#F5F5F5;
$text-light:#6C757D;
$input-border:$grayscale-3;
$focus-input-border:#4A4A49;
// ----- Text color classes.
.teal{color:$teal!important;}
.teal-on-hover:hover {
  transition: color 450ms ease-in-out;
  color:$teal!important;
}
.teal-dark{color:$teal-dark!important;}
.teal-very-dark{color:$teal-very-dark!important;}
.red{color:$red!important;}
.orange{color:$orange!important;}
.yellow{color:$yellow!important;}
.green{color:$green!important;}
.blue{color:$blue!important;}
.blue-dark{color:$blue-dark!important;}
.purple{color:$purple!important;}
.purple-dark{color:$purple-dark!important;}
.notifications-error{color:$notifications-error!important;}
.notifications-success{color:$notifications-success!important;}
.grayscale-1{color:$grayscale-1!important;}
.grayscale-2{color:$grayscale-2!important;}
.grayscale-3{color:$grayscale-3!important;}
.grayscale-4{color:$grayscale-4!important;}
.grayscale-5{color:$grayscale-5!important;}
// ----- Background color classes.
.bg-teal{background:$teal!important;}
.bg-teal-very-soft{background:$teal-very-soft!important;}
.bg-teal-dark{background:$teal-dark!important;}
.bg-teal-very-dark{background:$teal-very-dark!important;}
.bg-red{background:$red!important;}
.bg-red-very-soft{background:$red-very-soft!important;}
.bg-orange{background:$orange!important;}
.bg-yellow{background:$yellow!important;}
.bg-yellow-dark{background:$yellow-dark!important;}
.bg-green{background:$green!important;}
.bg-blue{background:$blue!important;}
.bg-blue-dark{background:$blue-dark!important;}
.bg-purple{background:$purple!important;}
.bg-purple-dark{background:$purple-dark!important;}
.bg-notifications-error{background:$notifications-error!important;}
.bg-notifications-success{background:$notifications-success!important;}
.bg-grayscale-1{background:$grayscale-1!important;}
.bg-grayscale-2{background:$grayscale-2!important;}
.bg-grayscale-2-on-hover:hover {
  transition: background-color 450ms ease-in-out;
  background-color: $grayscale-2!important;
}
.bg-grayscale-3{background:$grayscale-3!important;}
.bg-grayscale-4{background:$grayscale-4!important;}
.bg-grayscale-5{background:$grayscale-5!important;}
.bg-sidebar{background:$sidebar-light!important;}
// ----- Border color classes.
.border-teal{border-color:$teal!important;}
.border-grayscale-3{border-color:$grayscale-3!important;}
.border-yellow{border-color:$yellow!important;}
//   -------------------------------------------------------
//   Selection
//   -------------------------------------------------------
::-moz-selection {background:$teal; color:white;}
::selection {background:$teal; color:white;}
//   -------------------------------------------------------
//   Cohorts Colors
//   -------------------------------------------------------
$custom: $grayscale-4;
$year: $blue;
$form: $teal-dark;
$subject: $orange;
$student: $grayscale-2;
$characteristics: $purple;
//   -------------------------------------------------------
//   Pretty Checkbox Colors
//   -------------------------------------------------------
$pretty--color-default:#bdc3c7;
$pretty--color-primary:#428bca;
$pretty--color-info:#5bc0de;
$pretty--color-success:#5cb85c;
$pretty--color-warning:#f0ad4e;
$pretty--color-danger:#d9534f;
$pretty--color-dark:#5a656b;

.pretty.p-default input:checked~.state label:after {
  background-color: $teal !important;
}
.pretty.p-default input:checked~.state label:before {
  border-color: $teal !important;
}
//   -------------------------------------------------------
//   Progress Color Gradients
//   -------------------------------------------------------
.bg-teal-0{background:#97d8da !important;}
.bg-teal-10{background:#8bd3d4 !important;}
.bg-teal-20{background:#80cecf !important;}
.bg-teal-30{background:#76cacb !important;}
.bg-teal-40{background:#6cc5c6 !important;}
.bg-teal-50{background:#5ebebf !important;}
.bg-teal-60{background:#4bb6b6 !important;}
.bg-teal-70{background:#3cafaf !important;}
.bg-teal-80{background:#2ca8a8 !important;}
.bg-teal-90{background:#189f9f !important;}
.bg-teal-100{background:#009494 !important;}
//   -------------------------------------------------------
//   Other
//   -------------------------------------------------------
.bg-on-delete {
  transition: background ease-in-out 450ms;
  background: transparent;
}
